import {
  RestaurantState,
  RetaurantActionTypes,
  SET_ALL_RESTAURANTS,
  SET_CURRENT_RESTAURANT,
  SET_MSG_TO_CUSTOMERS,
  SET_RESTAURANT_ARTICLES,
  SET_RESTAURANT_TAGS,
} from './types';

const initialState: RestaurantState = {
  allRestaurants: [],
  currentRestaurant: null,
  selectedRestaurantArticles: [],
  msgToCustomers: null,
  currentTags: [],
};

const RestaurantReducer = (state = initialState, action: RetaurantActionTypes): RestaurantState => {
  switch (action.type) {
    case SET_ALL_RESTAURANTS:
      return {
        ...state,
        allRestaurants: action.data,
      };
    case SET_RESTAURANT_ARTICLES:
      return {
        ...state,
        selectedRestaurantArticles: action.data,
      };
    case SET_CURRENT_RESTAURANT:
      return {
        ...state,
        currentRestaurant: action.data,
      };
    case SET_MSG_TO_CUSTOMERS:
      return {
        ...state,
        msgToCustomers: action.data,
      };
    case SET_RESTAURANT_TAGS:
      return {
        ...state,
        currentTags: action.data,
      };
    default:
      return state;
  }
};

export default RestaurantReducer;
