import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
      'User Tag': 'User Tag',
      Percentage: 'Percentage',
      Fixed: 'Fixed',
      Product: 'Product',
      Bartender: 'Bartender',
    },
  },
  be: {
    translation: {
      'Welcome to React': 'Bienvenue à React et react-i18next',
      'User Tag': 'Gebruikertag',
      Percentage: 'Percentage',
      Fixed: 'Vast bedrag',
      Product: 'Gratis product',
      Bartender: 'Barman',
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.changeLanguage('be')

export default i18n;
